import { clientSize } from '@graphcommerce/framer-utils'
import { Row } from '@graphcommerce/next-ui/Row';
import {RowImageBannerFragment} from "./RowImageBanner.gql"
import {breakpointVal} from "@graphcommerce/next-ui";
import {RichText} from "@graphcommerce/graphcms-ui";
import {Image} from "@graphcommerce/image";
import {responsiveVal} from "@graphcommerce/next-ui/Styles";
import {Box} from "@mui/material";

export function RowImageBanner(props: RowImageBannerFragment) {
  const { bannerAsset, copy } = props

  return (
    <Row
      maxWidth={false}
      sx={{
        margin: '0 0 30px'
      }}
    >
      <Box
        sx={{
          display: 'grid',
          overflow: 'hidden',
          borderRadius: '16px',
          isolation: 'isolate',
        }}
      >
        <Box
          sx={(theme) => ({
            gridArea: '1 / 1',
            zIndex: 1,
            display: 'grid',
            justifyItems: 'center',
            alignContent: 'center',
            textAlign: 'center',
            p: {
              marginBottom: '0'
            },
            h1: {
              margin: '0 0 24px'
            },
            a: {
              height: '44px',
              width: '146px',
              borderRadius: '6px',
              padding: '12px 20px',
              backgroundColor: '#000',
              color: '#fff',
            },
            minHeight: { xs: 'min(70vh,600px)', md: 'min(90vh,1080px)' },
            [theme.breakpoints.up('sm')]: {
              padding: theme.spacings.xl,
              justifyItems: 'start',
              alignContent: 'center',
              textAlign: 'left',
              width: '50%',
            },
            '& p': {
              marginBottom: '0'
            }
          })}
        >
          <RichText
            {...copy}
          />
        </Box>
        <Box
          sx={{
            gridArea: '1 / 1',
            position: 'relative',
          }}
        >
          <Image
            alt={String(bannerAsset.alt)}
            src={bannerAsset.url}
            height={200}
            sx={{
              position: 'absolute',
              transition: 'transform 0.5s cubic-bezier(0.33, 1, 0.68, 1)',
              objectFit: 'cover',
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
      </Box>
    </Row>
  )
}
