import {useQuery} from "@graphcommerce/graphql";
import { StoreConfigDocument } from '@graphcommerce/magento-store';
import {responsiveVal, VariantImageLabelSwiper} from "@graphcommerce/next-ui";
import {Box, ButtonBase, Typography} from "@mui/material";
import {PopularCategoryFragment} from "./PopularCategory.gql";
import { Image } from '@graphcommerce/image'
import {RichText} from "@graphcommerce/graphcms-ui";

export function PopularCategory(props: PopularCategoryFragment) {
  const {title, description, categories,} = props;
  const { data, loading } = useQuery(StoreConfigDocument);

  if (!categories?.length || loading) return null;

  const secureBaseUrl = data?.storeConfig?.secure_base_url;

  if (!secureBaseUrl) return null;

  return (
    <VariantImageLabelSwiper
      maxWidth={false}
      title={title}
      copy={description}
      sx={{
        '& .Scroller-root': {
          alignItems: 'start',
          columnGap: '12px'
        },
        '& .RowLinks-title': {
          typography: 'h2',
          marginBottom: 0,
          textTransform: 'initial'
        },
        '& .RowLinks-copy': {
          my: '12px'
        }
      }}
    >
      {categories.map((category) =>
        category.popular_category_image && category.show_popular_category &&
        <ButtonBase
          href={String(category.url_key)}
          key={category.name}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            rowGap: '12px',
            width: '200px',
            '& img': {
              display: 'block',
              objectFit: 'contain'
            },
            '& picture': {
              width: '100%',
              height: '224px',
              background: 'rgba(0,0,0,0.04)',
              borderRadius: '16px'
            }
          }}
        >
          {category.popular_category_image && (
            <Image
              alt={String(category.name)}
              src={secureBaseUrl + category.popular_category_image}
              height={200}
              sx={() => ({
                height: '200px',
              })}
            />
          )}
          <Box sx={{ maxWidth: responsiveVal(120, 200) }}>
            <Typography paragraph>
              {category.name}
            </Typography>
          </Box>
        </ButtonBase>

      )}
    </VariantImageLabelSwiper>
  )

}